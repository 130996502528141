
import { defineComponent, reactive, onMounted } from 'vue';
import store from '@/store';
import Content from '@/components/parts/Content.vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { scrollFunc } from '@/components/contentsOperations';
import { updateContentsData } from '@/components/firebaseOperations';
import { pages } from '@/components/constants';

export default defineComponent({
  name: pages.category,
  components: {
    Content
  },
  setup () {
    const router = useRouter();

    const state = reactive({
      scrollFunc: scrollFunc(router.currentRoute.value.name) // スクロール時に実行されるクロージャ
    });

    onMounted(() => {
      if (
        !store.state.categoryContents[store.state.detailType as string]
          .loadedEverything
      ) {
        // カテゴリ別ページに関する、番組コンテンツのロードが完了していない場合
        window.addEventListener('scroll', state.scrollFunc); // 逐次追加のためのスクロールイベントを追加
      }
    });

    onBeforeRouteLeave(() => {
      window.removeEventListener('scroll', state.scrollFunc); // スクロールイベント削除
    });

    const init = async () => {
      if (
        store.state.categoryContents[store.state.detailType as string].values
          .length === 0
      ) {
        // 開こうとしているカテゴリページに関する、コンテンツデータがまだ1つもsetされていない場合
        await updateContentsData(
          pages.category,
          false,
          store.state.detailType as string
        ); // 最初の番組データをset
      }
    };

    init();

    return {
      state,
      store,
      router
    };
  }
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43448a0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "category" }
const _hoisted_2 = { id: "category-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Content = _resolveComponent("Content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.store.state.categoryList[_ctx.store.state.detailType]), 1),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.categoryContents[
            _ctx.store.state.detailType
          ]?.values, (content, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: content.id
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_Content, {
                index: index,
                content: content
              }, null, 8, ["index", "content"])
            ])
          ]))
        }), 128))
      ]),
      fallback: _withCtx(() => []),
      _: 1
    }))
  ]))
}